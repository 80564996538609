import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NbThemeModule, NbLayoutModule, NbSidebarModule, NbMenuModule, NbCardModule,
  NbToastrModule, NbGlobalPhysicalPosition, NbSpinnerModule, NbTooltipModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { LeadComponent, PasswordDialog, ViewDocDialog } from './leads/lead/lead.component';
import { LeadListComponent } from './leads/lead-list/lead-list.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { MatStepperModule } from '@angular/material/stepper';
import {
  MatFormFieldModule, MatInputModule, MatButtonModule, MatButtonToggleModule,
  MatCardModule, MatDialogModule, MatTableModule, MatPaginatorModule
} from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { ServerRequestConfigService } from './shared/server-request-config.service';
import { NgxfUploaderModule } from 'ngxf-uploader';
import { HeaderComponent } from './header/header.component';
import { httpInterceptorProviders } from './http-interceptors';
import { NewLeadComponent } from './leads/new-lead/new-lead.component';
import { AttributionComponent } from './attribution/attribution.component';
import { ChartsModule } from 'ng2-charts';
import { HelpComponent } from './help/help.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NotFoundComponent } from './not-found/not-found.component';
import { APP_BASE_HREF } from '@angular/common';
import { getBaseLocation } from './shared/share.service';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { NbIconModule } from '@nebular/theme';
import { ENgxViewerModule } from "e-ngx-viewer";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { LendingkartLoginComponent } from './lendingkartlogin/lendingkartlogin.component';
import { LeadBureauComponent, PasswordDialog as vd, ViewDocDialog as cd } from './leads/lead-bureau/lead-bureau.component';
import { LeadBureauBankingComponent, PasswordDialog as ls, ViewDocDialog as eu } from './leads/lead-bureau-banking/lead-bureau-banking.component';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    LeadComponent,
    PasswordDialog,
    LeadListComponent,
    SideBarComponent,
    HeaderComponent,
    NewLeadComponent,
    AttributionComponent,
    HelpComponent,
    NotFoundComponent,
    BreadcrumbsComponent,
    ViewDocDialog,
    LendingkartLoginComponent,
    LeadBureauComponent,
    LeadBureauBankingComponent,
    vd,cd,ls,eu
  ],
  entryComponents: [
    PasswordDialog,
    ViewDocDialog,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbMenuModule.forRoot(),
    NbCardModule,
    NbSidebarModule.forRoot(),
    NbToastrModule.forRoot({ position: NbGlobalPhysicalPosition.TOP_RIGHT, duration: 4000 }),
    NbSpinnerModule,
    NbEvaIconsModule,
    NbTooltipModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDialogModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    NgxfUploaderModule,
    ChartsModule,
    DeviceDetectorModule.forRoot(),
    NbLayoutModule,
    NbEvaIconsModule,
    NbIconModule,
    MatSelectModule,
    NbSpinnerModule,
    ENgxViewerModule,
    PdfViewerModule
  ],
  providers: [ServerRequestConfigService, httpInterceptorProviders, {
    provide: APP_BASE_HREF,
    useFactory: getBaseLocation
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
