import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { LeadSearchResponse, LeadData, RejectionReasonResponse } from 'src/app/shared/interfaces';
import { ShareService } from 'src/app/shared/share.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, from } from 'rxjs';
import { appConstants } from 'src/app/shared/constants';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { OrganizationAdmin } from 'src/app/shared/interfaces';
import { filter, map } from 'rxjs/operators';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import * as moment from 'moment';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.scss']
})
export class LeadListComponent implements OnInit {
  
  displayedColumns: string[] = ['serial', 'loanId', 'customerId', 'fullName', 'socialEmail', 'pan', 'loanStage', 'EMI', 'createdAt', 'softOffer', 'creditVariable'];
  downloadCsvColumns: string[] = ['loan_id', 'customer_id', 'first_name', 'last_name', 'social_email_id', 'pan', 'created_at', 'mobile_number1', 'dob',
    'aadhaar', 'gender', 'marital_status_id', 'qualification_type_id', 'current_residence_type_id', 'current_residence_stay_category_id', 'current_address_line1',
    'current_address_line2', 'current_city', 'current_state', 'current_pincode', 'salary', 'initial_disbursed_datetime', 'initial_pre_approved_datetime', 'work_email_id', 'salary_payment_mode_id', 'total_work_experience_category_id',
    'organization_type_id', 'profession_type_id', 'current_employment_tenure_category_id', 'company', 'current_company_address_line1', 'current_company_address_line2',
    'current_company_city', 'current_company_state', 'current_company_pincode', 'purpose_id', 'account_holder_full_name', 'account_number', 'ifsc', 'bank',
    'branch', 'city', 'district', 'state', 'status', 'profile_picture', 'pan_card', 'address_proof_1', 'last_month_salary_slip', 'bank_statement', 'stage', 
    'approved_amount', 'approved_tenure', 'approved_emi', 'other_reason', 'rejection_reasons'];
  dataSource: MatTableDataSource<LeadData>;
  lastFilteredLoanId: any;
  totalItems = 0;
  search_limit = 20;
  search_offset = 0;
  stageFilters: string;
  filteredSourceIds: number[];
  filteredStartDate: string;
  filteredEndDate: string;
  filteredLoanId: string;
  isAffiliate: boolean;
  affiliateUserSubscription: Subscription;
  subordinateOptions: OrganizationAdmin[] = [];
  subordinatesObj = {};
  filteredSources: string | number;
  allSubordinateIds: string;
  loanStageCategory = appConstants.loanStageCategory;
  filters: string[] = [];
  data: any;
  dropDownData: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  startDateForFilter;
  endDateForFilter;
  aipAmountAndTenure = {}; 

  constructor(private apiRequest: ApiRequestService,
    private shareService: ShareService,
    private toastr: NbToastrService,
    private route: ActivatedRoute, private authService: AuthService) {
    this.affiliateUserSubscription = this.shareService.isAffiliateUser.subscribe(res => {
      this.isAffiliate = res;
    });
  }

  ngOnInit() {   
    this.startDateForFilter = moment().subtract(3, 'months').format('YYYY-MM-DD');
    this.endDateForFilter = moment().format('YYYY-MM-DD');

    this.apiRequest.getDisplayData().subscribe(response => {
      this.dropDownData = response;
    });

    this.route.params.subscribe(data => {
      this.stageFilters = data.stages === 'undefined' ? undefined : data.stages;
      this.filteredSourceIds = data.source_ids && data.source_ids !== 'undefined' ? data.source_ids.split(',') : undefined;
      this.filteredStartDate = data.start_date && data.start_date !== 'undefined' ? data.start_date : undefined;
      this.filteredEndDate = data.end_date && data.end_date !== 'undefined' ? data.end_date : undefined;

      this.getLeadList();
      if (Object.keys(data).length !== 0) {
        this.setFilters(data);
      } else {
        this.filters = [];
      }
    });
  }  
  triggerPageEvent(event: PageEvent) {
    this.search_limit = event.pageSize;
    this.search_offset = event.pageIndex * event.pageSize;
    this.getLeadList(false);
  }
  private getLeadList(intiatePaginator = true) {
    const sourceId = +this.authService.getSourceId();

    const filterOption = {
      limit: this.search_limit,
      offset: this.search_offset,
      present_stage: this.stageFilters ? this.stageFilters.split(',') : undefined,
      source_ids: this.filteredSourceIds ? this.filteredSourceIds : [sourceId],
      start_date: this.filteredStartDate,
      end_date: this.filteredEndDate
    };
    this.shareService.showSpinner();
    this.apiRequest.getLeadList(filterOption).subscribe((leadsDataRes: LeadSearchResponse) => {
      this.data = leadsDataRes; 
     
      const list = [];
      for (const key in leadsDataRes.loan_data) {
        list.push(leadsDataRes.loan_data[key]);
      }
      leadsDataRes.loan_data = list;
      
      for (const loanData of leadsDataRes.loan_data) {
        for (const [stageName, stages] of Object.entries(this.loanStageCategory)) {
          if (stages.includes(loanData.present_stage)) {
            loanData['stageName'] = stageName;
          }
        }
      }

      leadsDataRes.loan_data.sort((a, b) => (a.loan_id > b.loan_id) ? -1 : 1);

      this.dataSource = new MatTableDataSource<LeadData>(leadsDataRes.loan_data);
      this.shareService.hideSpinner();
      const preRejectedLoanIds = leadsDataRes.loan_data
                                  .filter(loanDataObj => loanDataObj.present_stage === 'initial_pre_rejected')
                                  .map(dataObj => +dataObj.loan_id);
      for (const leadObj of leadsDataRes.loan_data) {
        if (appConstants.approvedStages.includes(leadObj.present_stage)) {
          leadObj['approvalData'] = leadObj.approved_amount + '/' + leadObj.approved_tenure;
        }
      }
      if (preRejectedLoanIds.length > 0) {
        forkJoin([this.apiRequest.getRejectionReasons(preRejectedLoanIds), this.apiRequest.getDisplayData()]).subscribe(res => {
          const rejectionMapping = res[1]['rejection_reasons'];
          let rejectionReasonRes = res[0];
          for (const leadObj of leadsDataRes.loan_data) {
            if (rejectionReasonRes[leadObj.loan_id] && rejectionReasonRes[leadObj.loan_id].length) {
              let reasonsArr = rejectionReasonRes[leadObj.loan_id].map(reasonNumber => rejectionMapping[reasonNumber]['name']);
              leadObj['rejection_reasons'] = reasonsArr;
              this.data.loan_data['rejection_reasons'] = reasonsArr;
            }
          }
        });
      }
      this.totalItems = leadsDataRes.count;
    });
  }

  downloadData() {
    let downloadData: any;
    const sourceId = +this.authService.getSourceId();

    if (this.data.count > 0) {
      this.shareService.showSpinner();
      const filterOption = {
        limit: this.data.count,
        offset: this.search_offset,
        present_stage: this.stageFilters ? this.stageFilters.split(',') : undefined,
        source_ids: this.filteredSourceIds ? this.filteredSourceIds : [sourceId],
        start_date: this.filteredStartDate,
        end_date: this.filteredEndDate
      };

      this.apiRequest.getLeadList(filterOption).subscribe((leadsDataRes: LeadSearchResponse) => {
        downloadData = leadsDataRes;
        this.shareService.hideSpinner();

        const list = [];
        for (const key in leadsDataRes.loan_data) {
          list.push(leadsDataRes.loan_data[key]);
        }

        downloadData.loan_data = list;

        downloadData.loan_data.forEach(element => {

          // adding loanStageText
          element.status = appConstants.loanStageTextMapping[element.present_stage];

          // marital status
          const marital_status_type = this.dropDownData['marital_status_type'];
          for (const type of marital_status_type) {
            if (type.id === element.marital_status_id) {
              element.marital_status_id = type.name;
            }
          }

          // current residency type id
          const residence_type = this.dropDownData.residence_type;
          for (const type of residence_type) {
            if (type.id === element.current_residence_type_id) {
              element.current_residence_type_id = type.name;
            }
          }

          // qualification
          const qualification_type = this.dropDownData.qualification_type;
          for (const type of qualification_type) {
            if (type.id === element.qualification_type_id) {
              element.qualification_type_id = type.name;
            }
          }

          // residency stay category
          const current_residence_stay_category = this.dropDownData.current_residence_stay_category;
          for (const type of current_residence_stay_category) {
            if (type.id === element.current_residence_stay_category_id) {
              element.current_residence_stay_category_id = type.name;
            }
          }

          // salary payment mode
          const salary_payment_mode = this.dropDownData.salary_payment_mode;
          for (const type of salary_payment_mode) {
            if (type.id === element.salary_payment_mode_id) {
              element.salary_payment_mode_id = type.name;
            }
          }

          // total_work_experience_category_id
          const total_work_experience_category = this.dropDownData.total_work_experience_category;
          for (const type of total_work_experience_category) {
            if (type.id === element.total_work_experience_category_id) {
              element.total_work_experience_category_id = type.name;
            }
          }

          // organization_type_id
          const organization_type = this.dropDownData.organization_type;
          for (const type of organization_type) {
            if (type.id === element.organization_type_id) {
              element.organization_type_id = type.name;
            }
          }

          // profession_type_id
          const profession_type = this.dropDownData.profession_type;
          for (const type of profession_type) {
            if (type.id === element.profession_type_id) {
              element.profession_type_id = type.type_name;
            }
          }

          // current_employment_tenure_category_id
          const current_employment_tenure_category = this.dropDownData.current_employment_tenure_category;
          for (const type of current_employment_tenure_category) {
            if (type.id === element.current_employment_tenure_category_id) {
              element.current_employment_tenure_category_id = type.name;
            }
          }

          // loan purpose
          const loan_purpose = this.dropDownData.loan_purpose;
          for (const type of loan_purpose) {
            if (type.id === element.purpose_id) {
              element.purpose_id = type.name;
            }
          }

          //Underwriter comment
          if (!appConstants.approvedStages.includes(element.present_stage)) {
            if (element.underwritter_comment) {
              element['other_reason'] = element.underwritter_comment;
            }
            else {
              element['other_reason'] = 'NA';
            }
          } else {
            element['other_reason'] = 'NA';
          }

          // document upload status
          for (const [key, value] of Object.entries(element['document_upload_status'])) {
            element[appConstants.mandatoryDocumentsMapping[key]] = value;
          }         

        });

        // loan stage mapping
        for (let loanData of leadsDataRes.loan_data) {
          for (let [stageName, stages] of Object.entries(this.loanStageCategory)) {
            if (stages.includes(loanData.present_stage)) {
              if (stageName === 'complete') {
                loanData['stage'] = 'UW Pending'.toUpperCase();  
              } else {
                loanData['stage'] = stageName.toUpperCase();
              }
            }
          }
        }

        // approved_amount and tenure
        const preRejectedLoanIds = leadsDataRes.loan_data.filter(loanDataObj => loanDataObj.present_stage === 'initial_pre_rejected').map(dataObj => +dataObj.loan_id);
        for (const leadObj of leadsDataRes.loan_data) {
          if (appConstants.approvedStages.includes(leadObj.present_stage)) {
            leadObj['approved_amount'] = leadObj.approved_amount;
            leadObj['approved_tenure'] = leadObj.approved_tenure;
          }
        }

        // rejection reasons
        if (preRejectedLoanIds.length > 0) {
          forkJoin([this.apiRequest.getRejectionReasons(preRejectedLoanIds), this.apiRequest.getDisplayData()]).subscribe(res => {
            const rejectionMapping = res[1]['rejection_reasons'];
            let rejectionReasonRes = res[0];
            for (const leadObj of leadsDataRes.loan_data) {
              if (rejectionReasonRes[leadObj.loan_id] && rejectionReasonRes[leadObj.loan_id].length) {
                let reasonsArr = rejectionReasonRes[leadObj.loan_id].map(reasonNumber => rejectionMapping[reasonNumber]['name']);
                leadObj['rejection_reasons'] = reasonsArr;
                downloadData.loan_data['rejection_reasons'] = reasonsArr;
              }
            }

            let sampleData = [];
            for (const leadObj of downloadData.loan_data) {
              let obj = {};
              for (const col of this.downloadCsvColumns) {
                if (leadObj[col]) {
                  obj[col] = leadObj[col];
                } else {
                  if (['profile_picture', 'pan_card', 'address_proof_1', 'last_month_salary_slip', 'bank_statement'].includes(col)) {
                    obj[col] = leadObj[col];
                  } else {
                    obj[col] = '';
                  }
                }
              }
              sampleData.push(obj);
            }
            const fileName = 'Customer_Data_' + moment().format(appConstants.fileDateTimeFormat);           
            sampleData.forEach(lineItem => {
              lineItem['initial_disbursed_datetime'] = lineItem['initial_disbursed_datetime'] ? lineItem['initial_disbursed_datetime'] : 'N.A';
              lineItem['initial_pre_approved_datetime'] = lineItem['initial_pre_approved_datetime'] ? lineItem['initial_pre_approved_datetime'] : 'N.A';
            });
            this.downloadDataToCsv(sampleData, fileName, this.downloadCsvColumns);
          });
        } else {
          let sampleData = [];
          for (const leadObj of downloadData.loan_data) {
            let obj = {};
            for (const col of this.downloadCsvColumns) {
              if (leadObj[col]) {
                obj[col] = leadObj[col];
              } else {
                if (['profile_picture', 'pan_card', 'address_proof_1', 'last_month_salary_slip', 'bank_statement'].includes(col)) {
                  obj[col] = leadObj[col];
                } else {
                  obj[col] = '';
                }
              }
            }
            sampleData.push(obj);
          }

          const fileName = 'Customer_Data_' + moment().format(appConstants.fileDateTimeFormat);
            this.downloadDataToCsv(sampleData, fileName, this.downloadCsvColumns);
        }
      }, (error) => {
        this.shareService.hideSpinner();
        this.toastr.danger('Error while downloading data. Please try again after some time.', 'Error');
      });
    } else {
      this.toastr.info('No data to download', 'No Data');
    }
  }

  applyFilter(filterValue) {
    if (!filterValue) {
      return;
    }
    const sourceId = +this.authService.getSourceId();
    this.dataSource.filter = filterValue.toString().trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {

      const filterOption = {
        loan_id: filterValue,
        source_ids: this.filteredSourceIds ? this.filteredSourceIds : [sourceId],
      }
      this.apiRequest.getLeadList(filterOption).subscribe((leadsDataRes: LeadSearchResponse) => {
        
        const list = [];
        for (const key in leadsDataRes.loan_data) {
          list.push(leadsDataRes.loan_data[key]);
        }
        leadsDataRes.loan_data = list;
        
        if (leadsDataRes && leadsDataRes.loan_data.length > 0) {

          // loan stage
          for (let [stageName, stages] of Object.entries(this.loanStageCategory)) {
            if (stages.includes(leadsDataRes.loan_data[0].present_stage)) {
              leadsDataRes.loan_data[0]['stageName'] = stageName;
            }
          }

          //approved amount
          if (appConstants.approvedStages.includes(leadsDataRes.loan_data[0].present_stage)) {
            leadsDataRes.loan_data[0]['approvalData'] = leadsDataRes.loan_data[0].approved_amount + '/' + leadsDataRes.loan_data[0].approved_tenure;
          }

          // if rejected
          if (leadsDataRes.loan_data[0].present_stage === 'initial_pre_rejected') {
            forkJoin([this.apiRequest.getRejectionReasons([+leadsDataRes.loan_data[0].loan_id]), this.apiRequest.getDisplayData()]).subscribe(res => {
              const rejectionMapping = res[1]['rejection_reasons'];
              let rejectionReasonRes = res[0];
              for (const leadObj of leadsDataRes.loan_data) {
                if (rejectionReasonRes[leadObj.loan_id] && rejectionReasonRes[leadObj.loan_id].length) {
                  let reasonsArr = rejectionReasonRes[leadObj.loan_id].map(reasonNumber => rejectionMapping[reasonNumber]['name']);
                  leadObj['rejection_reasons'] = reasonsArr;
                  this.data.loan_data['rejection_reasons'] = reasonsArr;
                }
              }
            });
          }

          this.lastFilteredLoanId = leadsDataRes.loan_data[0].loan_id;
          if (leadsDataRes.loan_data[0].attribution_status_id != 4) {
            this.dataSource.data.push(...leadsDataRes.loan_data);
            this.dataSource.filter = filterValue.toString().trim().toLowerCase();
          }
        }
      });
    }
  }

  clearUp(filterString) {
    if (filterString.length == 0) {
      let index = -1;
      for (let i = 0; i < this.dataSource.data.length; i++) {
        if (this.dataSource.data[i].loan_id == this.lastFilteredLoanId) {
          index = i;
        }
      }
      if (index !== -1) {
        this.dataSource.data.splice(index, 1);
      }
      this.dataSource.filter = filterString.trim().toLowerCase();
    }
  }

  private downloadDataToCsv(data, fileName, headers) {
    const leadsDataOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: headers,
      nullToEmptyString: true,
    };   
    new Angular5Csv(data, fileName, leadsDataOptions);
  }

  private setFilters(data) {

    if (!this.authService.isAffilliatedUser()) {
      this.filters.push(data.stages === 'undefined' || data.stages === undefined ? 'Total Logins' : data.stage_category.charAt(0).toUpperCase() + data.stage_category.slice(1).toLowerCase());
      data.start_date && data.start_date !== 'undefined' && data.start_date !== undefined ? this.filters.push(data.start_date) : null;
      data.start_date && data.end_date !== 'undefined' && data.end_date !== undefined ? this.filters.push(data.end_date) : null;

      if (data.source_ids !== 'undefined' && data.source_ids !== undefined) {
        let sourceIds = data.source_ids.split(',').map(x => parseInt(x));
        this.apiRequest.getOrganizationDetails().subscribe(organizationDetailsRes => {
          if (organizationDetailsRes && organizationDetailsRes.subordinates && organizationDetailsRes.subordinates.length && sourceIds.length < 2) {
            let names = [];
            from(organizationDetailsRes.subordinates)
              .pipe(
                filter(d => sourceIds.includes(d.admin_id)),
                map(x => x.full_name))
              .subscribe(dt => names.push(dt));
            this.filters.push(...names);
          }
        });
      }
    }
  }
}
